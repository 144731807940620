<template>
  <div>
    <TableFilters
      v-if="filters && filters.length"
      :filters="filters"
      @filter="fetchData"
    />

    <b-card no-body class="mb-0">
      <TableHeader
        :module-name="storeModule"
        :table-header="tableHeader"
        @search="fetchData"
        @add="openCrudForm"
      />

      <b-overlay :show="isLoading" rounded>
        <!-- TODO : move table fields map to the computed  -->
        <b-table
          class="position-relative"
          :items="dataArray"
          hover
          responsive
          striped
          primary-key="id"
          :fields="tableFields"
          show-empty
          :empty-text="
            $t('no_matching_records_found') || 'No matching records found'
          "
        >
          <template #cell(checkbox)="data">
            <span class="align-text-top text-capitalize">
              <b-form-checkbox
                :value="data.item.uuid"
                :checked="
                  selectedRows &&
                  selectedRows.length &&
                  selectedRows.find((e) => e === data.item.uuid)
                "
                class="align-text-top"
                @change="rowSelectedHandler($event, data.item.uuid)"
              ></b-form-checkbox>
            </span>
          </template>
          <template #cell(name_en)="data">
            {{ data.item.name }}
          </template>
          <template #cell(link)="data">
            <!-- todo add condition if youtube_thumbnail -->
            <img
              v-if="data.item && data.item.link"
              :src="getThumbnailLink(data.item.link)"
            />
            <!-- {{ data.item.name }} -->
          </template>

          <template #cell(image)="data">
            <img
              v-if="data.item.image"
              data-test="image"
              height="100"
              width="100"
              class="object-fit-contain mb-2"
              :src="data.item.image.path || data.item.image"
              :alt="data.item.image.alt_ar || ''"
            />
          </template>
          <template #cell(name_ar)="data">
            {{ data.item.name_ar }}
          </template>
          <template #cell(application_governorate)="data">
            {{ data.item.governorate ? data.item.governorate.name_ar : "-" }}
          </template>
          <template #cell(hospital_governorate)="data">
            {{
              data.item.hospital_governorate
                ? data.item.hospital_governorate.name_ar || "-"
                : "-"
            }}
          </template>
          <template #cell(hospital_direction)="data">
            {{
              data.item.hospital_direction
                ? data.item.hospital_direction.name_ar
                : "-"
            }}
          </template>

          <template #cell(is_read)="data">
            <b-badge :variant="data.item.is_read ? 'secondary' : 'info'" pill>
              {{
                data.item.is_read
                  ? $t("read") || "Read"
                  : $t("not_read") || "Not Read"
              }}
            </b-badge>
          </template>

          <template #cell(rejection_reason)="data">
            {{
              data.item.rejection_reason
                ? data.item.rejection_reason.name_ar
                : ""
            }}
          </template>
          <template #cell(users)="data">
            {{ data.item.users.map((el) => el.name).join(", ") }}
          </template>
          <template #cell(notes)="data">
            <ul class="m-0 p-0 list-style-none">
              <li
                v-if="data.item.rejected_reason"
                :class="
                  data.item.rejection_reason_notes ||
                  data.item.incomplete_application_reason
                    ? ' border-bottom'
                    : ''
                "
              >
                <b class="d-block">{{
                  $t("rejected_reason") || "Rejected reason"
                }}</b>
                {{ data.item.rejected_reason }}
              </li>

              <li
                v-if="data.item.rejection_reason_notes"
                :class="
                  data.item.incomplete_application_reason
                    ? ' border-bottom'
                    : ''
                "
              >
                <b class="d-block">{{
                  $t("rejection_reason_notes") || "Rejected reason"
                }}</b>
                {{ data.item.rejection_reason_notes }}
              </li>

              <li v-if="data.item.incomplete_application_reason">
                <b class="d-block">{{
                  $t("incomplete_application_reason") ||
                  "incomplete_application_reason"
                }}</b>
                {{ data.item.incomplete_application_reason }}
              </li>
              <li
                v-if="
                  !data.item.rejected_reason &&
                  !data.item.incomplete_application_reason
                "
              >
                -
              </li>
            </ul>
          </template>
          <template #cell(status)="data">
            <b-badge
              :variant="
                {
                  approved: 'success',
                  'incomplete application': 'warning',
                  rejected: 'danger',
                  4: 'info',
                }[data.item.status] || 'secondary'
              "
            >
              {{ $t(data.item.status) || data.item.status }}
            </b-badge>
          </template>
          <template #cell(edit_delete)="data">
            <div class="d-flex flex-column actions">
              <span
                v-for="(action, index) in actions"
                :key="index"
                class="w-100"
              >
                <AppButton
                  v-if="displayAction(action, data.item)"
                  class="w-100 mb-1"
                  :variant="action.variant || 'primary'"
                  @click="handleActionEvent(action, data.item)"
                >
                  {{
                    translate(action.event || action.label) ||
                    action.event ||
                    action.label
                  }}
                </AppButton>
              </span>
            </div>
          </template>
        </b-table>

        <TablePagination
          :meta="dataArrayMeta"
          @change-pagination="fetchData({ page: $event || 1 })"
        />
      </b-overlay>
    </b-card>
    <!-- v-if="userCan('article-tag-edit') || userCan('article-tag-add')" -->
    <CrudForm
      v-if="formFields"
      :id="`${storeModule}-crud-form`"
      :initial-data="activeItem"
      :store-module="storeModule"
      :form-fields="formFields"
      :mode="crudFormMode"
      @hide="hideCrudForm"
    />

    <Warning
      id="delete-warning-modal"
      :is-loading="isDeleting"
      :payload="itemsToBeDeleted || activeItem"
      @cancel="hideWarning('delete-warning-modal')"
      @approve="deleteActionHandler(activeItem)"
    />

    <Warning
      v-if="activeAction"
      id="warning-modal"
      :is-loading="isUpdating"
      :payload="activeItem"
      :title="activeAction ? activeAction.label : $t('are_you_sure')"
      :action-label="activeAction ? activeAction.label : $t('yes')"
      :message="warningMessage"
      :variant="activeAction ? activeAction.variant : 'primary'"
      @cancel="hideWarning('warning-modal')"
      @approve="approveWarning(activeItem, activeAction)"
    >
      <ServerErrors v-if="serverErrs" :errors-bag="serverErrs" />

      <AppSelect
        v-if="activeAction && activeAction.displayRejectionReasons"
        id="rejection_reason -selectbox"
        v-model="rejection_reason"
        :placeholder="$t('rejection_reason') || 'Rejection Reason'"
        :field-label="$t('rejection_reason') || 'Rejection Reason'"
        :options="rejectionReasons"
        search-store-action="rejection_reasons/fetchAllData"
        required
      />
      <AppTextarea
        v-if="activeAction && activeAction.displayNotes"
        id="warning-notes"
        v-model="warning_notes"
        data-test="warning-notes"
        rows="3"
        max-rows="8"
        :label="$t('notes') || 'notes'"
        :placeholder="$t('notes') || 'notes'"
      />
    </Warning>
  </div>
</template>

<script>
import { BCard, BTable, BOverlay, BFormCheckbox, BBadge } from "bootstrap-vue";
import i18n from "@/libs/i18n/index";

import userCan from "@/mixins/UserCan";
import {
  AppButton,
  AppTextarea,
  AppSelect,
  ServerErrors,
} from "@/components/form/index";
// TODO : Dynamic import components if props is passed
import TablePagination from "./TablePagination.vue";
import TableFilters from "./TableFilters.vue";
import TableHeader from "./TableHeader.vue";
import CrudForm from "@/components/UI/CrudForm.vue";

export default {
  name: "AppTable",
  components: {
    ServerErrors,
    TablePagination,
    TableHeader,
    TableFilters,
    CrudForm,
    BBadge,
    BFormCheckbox,
    BCard,
    BTable,
    BOverlay,
    AppButton,
    AppTextarea,
    AppSelect,
  },
  props: {
    storeModule: {
      type: String,
      required: true,
    },
    filters: {
      type: Array,
      required: false,
      default: null,
    },
    actions: {
      type: Array,
      required: false,
      default: null,
    },
    tableFields: {
      type: Array,
      required: false,
      default: null,
    },
    formFields: {
      type: Array,
      required: false,
      default: null,
    },
    tableHeader: {
      type: Object,
      required: false,
      default: null,
    },
    outerForm: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      activeItem: null,
      activeAction: null,
      selectedRows: [],
      itemsToBeDeleted: [],
      crudFormMode: "edit",
      warning_notes: "",
      rejection_reason: "",
    };
  },
  computed: {
    warningMessage() {
      if (!this.activeAction) return "";

      return `${this.activeAction.message || ""} (${
        (this.activeAction.message_key &&
          this.activeItem &&
          this.activeItem[this.activeAction.message_key]) ||
        ""
      })`;
    },
    rejectionReasons() {
      return this.$store.state.rejection_reasons.data;
    },
    dataArray() {
      return this.storeModule && this.$store.state[this.storeModule].data;
    },
    dataArrayMeta() {
      return this.storeModule && this.$store.state[this.storeModule].meta;
    },
    serverErrs() {
      return this.storeModule && this.$store.state[this.storeModule].errors;
    },
    displayAction() {
      return (action, item) => {
        if (
          action["permission"] &&
          !userCan(`${this.storeModule}-${action["permission"]}`)
        )
          return false;

        if (
          !action["permission"] &&
          !userCan(`${this.storeModule}-${action.event || action.label}`)
        )
          return false;

        // ex : if the item contains is_read flag === 1 && if user have the permission
        if (
          action["condition"] &&
          !Array.isArray(action["condition"]) &&
          item[action["condition"]]
        )
          return false;

        if (action["condition"] && Array.isArray(action["condition"])) {
          if (!action["condition"].length) return false;
          return !!action["condition"]
            .map((element) => {
              if (item[element.key] !== element.value) return true;
              return false;
            })
            .filter(Boolean).length;
        }

        return true;
      };
    },
    isLoading() {
      return (
        this.storeModule &&
        this.$store.state[this.storeModule].isLoading.fetching
      );
    },

    isUpdating() {
      return (
        this.storeModule &&
        this.$store.state[this.storeModule].isLoading.updating
      );
    },
    isDeleting() {
      return (
        this.storeModule &&
        this.$store.state[this.storeModule].isLoading.deleting
      );
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    userCan,
    translate(word) {
      return i18n.t(word) || word;
    },
    getThumbnailLink(link) {
      if (!link) return null;
      if (!link.includes("youtube")) return link;
      const url = new URL(link);
      const params = new URLSearchParams(url.search);
      const videoId = params.get("v");
      return `https://img.youtube.com/vi/${videoId}/default.jpg`;
    },
    handleActionEvent(action = null, activeItem) {
      if (!action) return;
      // if (!action["function"]) action["function"]?.();

      // handle edit || delete actions
      if (action["event"] === "edit" || action["event"] === "delete") {
        this[`${action["event"]}Handler`]?.(activeItem);
        return;
      }

      this.$emit(action.event, { action, activeItem });

      // handle the rest of actions
      this.activeItem = activeItem;
      this.activeAction = action;
      if (action.disableModal) return;
      this.$nextTick(() => this.$bvModal.show(`warning-modal`));
    },
    editHandler(item) {
      item && this.openCrudForm(item, "edit");
    },
    deleteHandler(item = null) {
      this.activeItem = item;
      this.$bvModal.show("delete-warning-modal");
    },
    viewHandler(item) {
      item && this.openCrudForm(item, "view");
    },
    deleteActionHandler(activeItem) {
      if (!activeItem) return;
      this.$store
        .dispatch(`${this.storeModule}/deleteData`, {
          uuid: activeItem?.uuid,
        })
        .then(() => {
          this.hideWarning();
        });
    },
    approveWarning(activeItem, activeAction) {
      if (!activeItem || !activeAction || !activeAction.storeAction) return;

      this.$store
        .dispatch(`${this.storeModule}/${activeAction.storeAction}`, {
          // ...activeItem,
          ...(Object.keys(activeItem).includes("is_read") && {
            is_read: !activeItem.is_read ? 1 : 0,
          }),
          uuid: activeItem?.uuid,
          ...(activeAction["displayNotes"] && {
            [activeAction["notesKey"] || "notes"]: this.warning_notes,
          }),
          ...(activeAction["displayRejectionReasons"] && {
            [activeAction["rejectionReasonsKey"] || "rejection_reason"]:
              this.rejection_reason?.uuid || this.rejection_reason,
          }),
        })
        .then(() => {
          this.hideWarning();
        });
    },
    openCrudForm(item = null, mode = "add") {
      if (item) this.activeItem = item;
      if (mode) this.crudFormMode = mode;
      if (this.outerForm) {
        this.$bvModal.show(this.outerForm);
        this.$emit("displayForm", item);
        return;
      }

      this.$bvModal.show(`${this.storeModule}-crud-form`);
    },
    hideCrudForm() {
      this.activeItem = null;
    },
    rowSelectedHandler(state, uuid) {
      if (
        this.selectedRows &&
        this.selectedRows.length &&
        this.selectedRows.find((e) => e === uuid)
      ) {
        this.selectedRows = this.selectedRows.filter((e) => e !== uuid);
      } else {
        this.selectedRows.push(uuid);
      }
    },
    fetchData(param) {
      this.$store.dispatch(`${this.storeModule}/fetchData`, param);
    },
    hideWarning() {
      this.activeAction = null;
      this.activeItem = null;
      this.warning_notes = null;
      this.$bvModal.hide("warning-modal");
      this.$bvModal.hide("delete-warning-modal");
    },
  },
};
</script>
