<template>
  <AppForm
    :id="id"
    :ref="`${id}-form`"
    class="p-1"
    :title="
      initialData
        ? $t('edit_form', { name: initialData.name }) ||
          'Edit' + initialData.name
        : $t('add_new') || 'Add New'
    "
    :errors-bag="errorsBag"
    :success-message="successMessage"
    :is-loading="isLoading"
    :display-submit-and-add-new="false"
    :hide-footer="mode === 'view'"
    @hide="hideForm"
    @submit.prevent="handleSubmit"
  >
    <template v-if="formFields && formFields.length">
      <div v-for="(field, index) in formFields" :key="index">
        <component
          :is="field.component || 'AppInput'"
          v-if="field.attrs && field.component !== 'AppGallery'"
          v-model="formData[field.attrs.vModel]"
          :disabled="mode === 'view'"
          class="mb-2"
          v-bind="field.attrs"
          :initialValue="initialData && initialData[field.attrs.vModel]"
          @changeDate="datepickerFilterHandler(field.attrs.vModel, $event)"
          @remove="removeImageHandler"
          @changeFiles="formData[field.attrs.vModel] = $event"
        />
        <!-- <AppCheckboxes
      :id="`app-checkboxes`"
      v-model="permissionData['actions']"
      text-field="name"
      value-field="uuid"
      :options="actions"
      :disabled="mode === 'view'"
      :label="$t('actions') || 'Actions'"
      :initial-value="oldSelectedActions"
    /> -->

        <div
          v-if="field.component === 'AppGallery'"
          class="mt-2 pt-2 border-top"
        >
          <img
            v-if="formData['image']"
            width="100%"
            data-test="image"
            height="200"
            class="object-fit-contain mb-2"
            :src="formData['image']['path']"
            :alt="formData['image']['alt_ar']"
          />
          <AppButton
            variant="outline-secondary"
            data-test="choose_image_btn"
            class="w-100"
            type="button"
            :label="chooseImageLabel"
            @click="openGallaryModal"
          />
          <GalleryModal @select="selectImageHandler" />
        </div>
      </div>
    </template>
  </AppForm>
</template>

<script>
// TODO : Dynamic import Components if props are passed
import {
  AppInput,
  AppForm,
  AppFilePreview,
  AppTextarea,
  AppButton,
  AppSelect,
  AppDatePicker,
  AppCheckboxes,
} from "@/components/form/index";
import { toFormData } from "@/utils/index";
const GalleryModal = () => import("@/components/UI/GalleryModal.vue");

import i18n from "@/libs/i18n/index";

export default {
  name: "CrudForm",
  components: {
    AppInput,
    AppForm,
    AppFilePreview,
    AppTextarea,
    AppSelect,
    AppCheckboxes,
    GalleryModal,
    AppButton,
    AppDatePicker,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    initialData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    formFields: {
      type: Array,
      required: false,
      default: null,
    },
    mode: {
      type: String,
      required: false,
      default: "add",
    },
    storeModule: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      successMessage: null,
    };
  },
  computed: {
    isLoading() {
      return (
        this.$store.state[this.storeModule].isLoading.creating ||
        this.$store.state[this.storeModule].isLoading.updating
      );
    },
    errorsBag() {
      return this.$store.state[this.storeModule].errors;
    },
    chooseImageLabel() {
      return this.formData["image"]
        ? i18n.t("change_image") || "Change Image"
        : i18n.t("choose_image") || "Choose Image";
    },
  },
  watch: {
    initialData: {
      handler(val) {
        if (!val) {
          this.formData = {};
          return;
        }
        this.formData = {
          ...JSON.parse(JSON.stringify(val)),
        };
        // image: val.path,
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    openGallaryModal() {
      this.$bvModal.show("gallary-modal");
    },
    selectImageHandler(image) {
      // this.formData["image"] = image;
      this.$set(this.formData, "image", image);
    },
    datepickerFilterHandler(key, date = null) {
      this.$set(this.formData, key, date);
    },
    // in case the component  AppFilePreview exists
    removeImageHandler() {
      if (this.mode === "view") return;
      Object.keys(this.formData).forEach((key) => {
        if (key === "image") {
          this.formData[key] = null;
        }
      });
    },
    handleSubmit() {
      if (this.mode === "view") return;
      const actionName = this.initialData ? "updateData" : "createData";

      this.$store
        .dispatch(
          `${this.storeModule}/${actionName}`,
          toFormData(this.formData)
        )
        .then((res) => {
          this.successMessage =
            this.$t("data_has_been_saved") || "data has been saved";
          setTimeout(() => {
            this.successMessage = null;
          }, 1000);
          // this.$emit("uploaded", res?.data);
          this.hideForm();
        });
    },
    hideForm() {
      this.$bvModal.hide(this.id);
      this.$store.dispatch(`${this.storeModule}/resetModule`);
      this.formData = {};
      this.$emit("hide");
      this.formData = {};
    },
  },
};
</script >
