<template>
  <div
    class="
      m-2
      d-flex
      align-items-center
      justify-content-end
      flex-column flex-md-row
    "
  >
    <TableSearch
      v-if="tableHeader.multiKeySearch"
      :search-by-options="tableHeader.multiKeySearch && tableHeader.searchByOptions? tableHeader.searchByOptions || [] : null"
      :initial-selected="tableHeader && tableHeader.initialSelected ? tableHeader.initialSelected : initialSelected"
      @search="$emit('search', $event)"
    />
    <AppButton
      v-if="displayAddNewButton && tableHeader.addNewBtn"
      variant="primary"
      class="ml-2"
      :label="tableHeader.addNewBtn.label || $t('add_new') || 'Add New'"
      data-test="add-new-btn"
      v-bind="
        typeof tableHeader.addNewBtn === 'object' ? tableHeader.addNewBtn : {}
      "
      @click="addNewHandler(tableHeader.addNewBtn)"
    />
  </div>
</template>

<script>
import TableSearch from "@/components/table/TableSearch.vue";
import { AppButton } from "@/components/form/index";
import userCan from "@/mixins/UserCan";

export default {
  components: {
    TableSearch,
    AppButton,
  },
  props: {
    searchByOptions: {
      required: false,
      type: Array,
      default: null,
    },
    initialSelected: {
      type: String,
      default: "name",
    },
    displayAddNewButton: {
      type: Boolean,
      default: true,
    },
    moduleName: {
      type: String,
      default: null,
    },
    tableHeader: {
      type: Object,
      default: null,
    },
  },
  methods: {
    userCan,
    addNewHandler(options) {
      if (!options) return;
      !options.to && this.$emit("add");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>