<template>
  <b-card v-if="filters && filters.length">
    <b-card-header class="p-0">
      <h5 class="mb-0">
        {{ $t("filters") || "Filters" }}
      </h5>
      <AppButton
        :variant="!expandFilters ? 'primary' : 'secondary'"
        class="d-flex align-items-center button-custom-width"
        @click="expandFilters = !expandFilters"
      >
        <feather-icon
          icon="ChevronsDownIcon"
          size="16"
          class="text-white transition mr-1"
          :class="expandFilters ? 'rotate-180' : ''"
        />
        <span>
          {{
            expandFilters
              ? $t("collapse") || "Collapse"
              : $t("expand") || "Expand"
          }}
        </span>
      </AppButton>
    </b-card-header>
    <transition name="slide">
      <div v-if="expandFilters">
        <b-card-body>
          <b-row>
            <b-col
              v-for="(filter, index) in filters"
              :key="index"
              :cols="filter.cols || '12'"
              :md="filter.md || '4'"
              :class="filter.class || 'mb-2'"
            >
              <AppSelect
                v-if="
                  filter.name &&
                  filter.componentType &&
                  filter.componentType === 'AppSelect'
                "
                v-model="filtersData[filter.name]"
                form-group-classes="mb-0"
                :dir="isRTL ? 'rtl' : 'ltr'"
                :options="filter.options || []"
                :label="$t(filter.name) || filter.name"
                :placeholder="$t('all') || 'all'"
                :search-store-action="filter.storeAction || null"
                @input="filterHandler(filtersData)"
              />

              <AppDatePicker
                v-if="
                  filter.name &&
                  filter.componentType &&
                  filter.componentType === 'AppDatePicker'
                "
                ref="datePicker"
                :value="filtersData[filter.name]"
                :label="$t(filter.name) || filter.name"
                @change="datepickerFilterHandler(filter.name, $event)"
              />
              <!--  -->
            </b-col>
          </b-row>
          <transition name="slide">
            <b-row v-if="dataIsFiltered">
              <b-col
                cols="12"
                class="
                  mb-md-0
                  my-2
                  d-flex
                  align-items-center
                  justify-content-end
                "
              >
                <AppButton
                  variant="danger"
                  class=""
                  @click="resetFilters(filtersData)"
                >
                  {{ $t("Reset_filters") || "Reset Filters" }}
                </AppButton>
              </b-col>
            </b-row>
          </transition>
        </b-card-body>
      </div>
    </transition>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue";
import { AppButton, AppSelect, AppDatePicker } from "@/components/form/index";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    AppButton,
    AppSelect,
    AppDatePicker,
  },
  props: {
    filters: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      filtersData: {},
      expandFilters: false,
    };
  },
  computed: {
    dataIsFiltered() {
      return !!Object.keys(this.filtersData)
        .map((key) => {
          if (
            this.filtersData[key] !== null &&
            this.filtersData[key].length !== 0
          )
            return true;
          return false;
        })
        ?.filter(Boolean)?.length;
    },
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
  },
  created() {
    // this.$store.dispatch("refuseReasons/fetchAllData");
  },
  methods: {
    datepickerFilterHandler(name, date = null) {
      this.$set(this.filtersData, name, date);
      this.filterHandler(this.filtersData);
    },
    resetFilters(filters) {
      // clear component data
      Object.keys(filters).forEach((key) => {
        this.$set(filters, key, null);
      });

      // clear date pickers
      this.$refs.datePicker.forEach((el) => {
        el?.clearValue();
      });
      this.$emit("filter", {});
    },
    filterHandler(filters) {
      const filtersVals = {};
      Object.keys(filters).forEach((key) => {
        console.log(
          "🚀 ~ file: TableFilters.vue ~ line 164 ~ Object.keys ~ filters",
          filters
        );
        console.log(
          "🚀 ~ file: TableFilters.vue ~ line 171 ~ Object.keys ~ key",
          key
        );
        if (Array.isArray(filters[key])) {
          filtersVals[key] = filters[key].join(",");
        } else {
          filtersVals[key] = filters[key]?.value || filters[key];
        }
      });

      this.$emit("filter", filtersVals);
    },
  },
};
</script>

<style lang="scss">
.button-custom-width {
  width: 120px;
  min-width: 120px;
}
</style>
